import React from 'react'
import { makeStyles, Chip, Avatar } from '@material-ui/core'
import { SanityPerson } from '../../@types/graphql-types'
import Img, { FixedObject } from 'gatsby-image'

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  listItem: {
    backgroundColor: '#0a78dd',
    flexShrink: 1,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: '0.7rem',
  },
  tag: {
    lineHeight: '1.8',

    fontWeight: 'bold',
    backgroundColor: `#0a78dd`,
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    paddingLeft: theme.spacing(0.2),
    paddingRight: theme.spacing(0.2),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
  },
}))

interface Tags {
  tags: Pick<SanityPerson, 'fullName' | 'avatar'>[]
}
export const Tags: React.FC<Tags> = ({ tags }) => {
  const classes = useStyles(undefined)
  return (
    <div className={classes.list}>
      {tags.map((tag, key) => (
        <Chip
          key={key}
          avatar={
            tag.avatar && (
              <Avatar>
                <Img fixed={tag.avatar.asset.fixed as FixedObject} />
              </Avatar>
            )
          }
          label={tag?.fullName}
          className={classes.listItem}
        />
      ))}
    </div>
  )
}

export default Tags
